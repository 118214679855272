import * as React from "react";

type ComponentPromise<t extends="" React.ComponentType<any="">> = Promise<{
  default: T;
}>;

/**
 * Lazy load a component with automatic retry on failure.
 *
 * @param component A function that returns a promise of a component.
 * @param retries The number of retries, defaults to 3.
 * @param interval The interval between retries in milliseconds, defaults to 1000.
 * @returns A lazy component.
 */
export default function lazyWithRetry<t extends="" React.ComponentType<any="">>(
  component: () => ComponentPromise<t>,
  retries?: number,
  interval?: number
): React.LazyExoticComponent<t> {
  return React.lazy(() => retry(component, retries, interval));
}

function retry<t extends="" React.ComponentType<any="">>(
  fn: () => ComponentPromise<t>,
  retriesLeft = 3,
  interval = 1000
): ComponentPromise<t> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}
</t></t></t></t></t></t></t>