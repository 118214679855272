import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { s } from "@shared/styles";
import { isMac } from "@shared/utils/browser";
import Flex from "~/components/Flex";
import InputSearch from "~/components/InputSearch";
import Key from "~/components/Key";
import { metaDisplay, altDisplay } from "~/utils/keyboard";

function KeyboardShortcuts() {
  const { t } = useTranslation();
  const categories = React.useMemo(
    () => [
      {
        title: t("Navigation"),
        items: [
          {
            shortcut: (
              <>
                <key symbol="">{metaDisplay}</key> + <key>k</key>
              </>
            ),
            label: t("Open command menu"),
          },
          {
            shortcut: <key>n</key>,
            label: t("New document"),
          },
          {
            shortcut: <key>e</key>,
            label: t("Edit current document"),
          },
          {
            shortcut: <key>m</key>,
            label: t("Move current document"),
          },
          {
            shortcut: <key>h</key>,
            label: t("Open document history"),
          },
          {
            shortcut: (
              <>
                <key>/</key> or <key>t</key>
              </>
            ),
            label: t("Jump to search"),
          },
          {
            shortcut: <key>d</key>,
            label: t("Jump to home"),
          },
          {
            shortcut: (
              <>
                <key>Ctrl</key> + <key symbol="">{oldDisplay}</key> + <key>h</key>
              </>
            ),
            label: t("Table of contents"),
          },
          {
            shortcut: (
              <>
                <key symbol="">{metaDisplay}</key> + <key>.</key>
              </>
            ),
            label: t("Toggle navigation"),
          },
          {
            shortcut: (
              <>
                <key symbol="">{metaDisplay}</key> + <key>f</key>
              </>
            ),
            label: t("Focus search input"),
          },
          {
            shortcut: <key>?</key>,
            label: t("Open this guide"),
          },
          {
            shortcut: (
              <>
                <key symbol="">{metaDisplay}</key> + <key>Enter</key>
              </>
            ),
            label: t("Go to link"),
          },
          {
            shortcut: (
              <>
                <key symbol="">{metaDisplay}</key> + <key symbol="">⇧</key> +{" "}
                <key>p</key>
              </>
            ),
            label: t("Publish document and exit"),
          },
          {
            shortcut: (
              <>
                <key symbol="">{metaDisplay}</key> + <key>s</key>
              </>
            ),
            label: t("Save document"),
          },
          {
            shortcut: (
              <>
                <key symbol="">{isMac() ? metaDisplay : "⇧"}</key> + <key>Esc</key>
              </>
            ),
            label: t("Cancel editing"),
          },
        ],
      },
      {
        title: t("Formatting"),
        items: [
          {
            shortcut: (
              <>
                <key>Ctrl</key> + <key symbol="">⇧</key> + <key>0</key>
              </>
            ),
            label: t("Paragraph"),
          },
          {
            shortcut: (
              <>
                <key>Ctrl</key> + <key symbol="">⇧</key> + <key>1</key>
              </>
            ),
            label: t("Large header"),
          },
          {
            shortcut: (
              <>
                <key>Ctrl</key> + <key symbol="">⇧</key> + <key>2</key>
              </>
            ),
            label: t("Medium header"),
          },
          {
            shortcut: (
              <>
                <key>Ctrl</key> + <key symbol="">⇧</key> + <key>3</key>
              </>
            ),
            label: t("Small header"),
          },
          {
            shortcut: (
              <>
                <key>Ctrl</key> + <key symbol="">⇧</key> + <key>\</key>
              </>
            ),
            label: t("Code block"),
          },
          {
            shortcut: (
              <>
                <key symbol="">{metaDisplay}</key> + <key>b</key>
              </>
            ),
            label: t("Bold"),
          },
          {
            shortcut: (
              <>
                <key symbol="">{metaDisplay}</key> + <key>i</key>
              </>
            ),
            label: t("Italic"),
          },
          {
            shortcut: (
              <>
                <key symbol="">{metaDisplay}</key> + <key>u</key>
              </>
            ),
            label: t("Underline"),
          },
          {
            shortcut: (
              <>
                <key symbol="">{metaDisplay}</key> + <key>d</key>
              </>
            ),
            label: t("Strikethrough"),
          },
          {
            shortcut: (
              <>
                <key symbol="">{metaDisplay}</key> + <key>k</key>
              </>
            ),
            label: t("Link"),
          },
          {
            shortcut: (
              <>
                <key symbol="">{metaDisplay}</key> + <key>z</key>
              </>
            ),
            label: t("Undo"),
          },
          {
            shortcut: (
              <>
                <key symbol="">{metaDisplay}</key> + <key symbol="">⇧</key> +{" "}
                <key>z</key>
              </>
            ),
            label: t("Redo"),
          },
        ],
      },
      {
        title: t("Lists"),
        items: [
          {
            shortcut: (
              <>
                <key>Ctrl</key> + <key symbol="">⇧</key> + <key>7</key>
              </>
            ),
            label: t("Todo list"),
          },
          {
            shortcut: (
              <>
                <key>Ctrl</key> + <key symbol="">⇧</key> + <key>8</key>
              </>
            ),
            label: t("Bulleted list"),
          },
          {
            shortcut: (
              <>
                <key>Ctrl</key> + <key symbol="">⇧</key> + <key>9</key>
              </>
            ),
            label: t("Ordered list"),
          },
          {
            shortcut: <key>Tab</key>,
            label: t("Indent list item"),
          },
          {
            shortcut: (
              <>
                <key symbol="">⇧</key> + <key>Tab</key>
              </>
            ),
            label: t("Outdent list item"),
          },
          {
            shortcut: (
              <>
                <key symbol="">{oldDisplay}</key> + <key symbol="">↑</key>
              </>
            ),
            label: t("Move list item up"),
          },
          {
            shortcut: (
              <>
                <key symbol="">{oldDisplay}</key> + <key symbol="">↓</key>
              </>
            ),
            label: t("Move list item down"),
          },
        ],
      },
      {
        title: "Markdown",
        items: [
          {
            shortcut: (
              <>
                <key>#</key> <key>Space</key>
              </>
            ),
            label: t("Large header"),
          },
          {
            shortcut: (
              <>
                <key>##</key> <key>Space</key>
              </>
            ),
            label: t("Medium header"),
          },
          {
            shortcut: (
              <>
                <key>###</key> <key>Space</key>
              </>
            ),
            label: t("Small header"),
          },
          {
            shortcut: (
              <>
                <key>1.</key> <key>Space</key>
              </>
            ),
            label: t("Numbered list"),
          },
          {
            shortcut: (
              <>
                <key>-</key> <key>Space</key>
              </>
            ),
            label: t("Bulleted list"),
          },
          {
            shortcut: (
              <>
                <key>[ ]</key> <key>Space</key>
              </>
            ),
            label: t("Todo list"),
          },
          {
            shortcut: (
              <>
                <key>&gt;</key> <key>Space</key>
              </>
            ),
            label: t("Blockquote"),
          },
          {
            shortcut: <key>---</key>,
            label: t("Horizontal divider"),
          },
          {
            shortcut: <key>{"```"}</key>,
            label: t("Code block"),
          },
          {
            shortcut: (
              <>
                <key>$$$</key> <key>Space</key>
              </>
            ),
            label: t("LaTeX block"),
          },
          {
            shortcut: <key>{":::"}</key>,
            label: t("Info notice"),
          },
          {
            shortcut: "_italic_",
            label: t("Italic"),
          },
          {
            shortcut: "**bold**",
            label: t("Bold"),
          },
          {
            shortcut: "~~strikethrough~~",
            label: t("Strikethrough"),
          },
          {
            shortcut: "`code`",
            label: t("Inline code"),
          },
          {
            shortcut: "$$latex$$",
            label: t("Inline LaTeX"),
          },
          {
            shortcut: "==highlight==",
            label: t("Highlight"),
          },
        ],
      },
    ],
    [t]
  );
  const [searchTerm, setSearchTerm] = React.useState("");
  const handleChange = React.useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);
  const handleKeyDown = React.useCallback((event) => {
    if (event.currentTarget.value && event.key === "Escape") {
      event.preventDefault();
      event.stopPropagation();
      setSearchTerm("");
    }
  }, []);
  return (
    <flex column="">
      <inputsearch onChange="{handleChange}" onKeyDown="{handleKeyDown}" value="{searchTerm}"></inputsearch>
      {categories.map((category, x) => {
        const filtered = searchTerm
          ? category.items.filter((item) =>
              item.label.toLowerCase().includes(searchTerm.toLowerCase())
            )
          : category.items;

        if (!filtered.length) {
          return null;
        }

        return (
          <react.fragment key="{x}">
            <header>{category.title}</header>
            <list>
              {filtered.map((item) => (
                <react.fragment key="{item.label}">
                  <keys>
                    <span>{item.shortcut}</span>
                  </keys>
                  <label>{item.label}</label>
                </react.fragment>
              ))}
            </list>
          </react.fragment>
        );
      })}
    </flex>
  );
}

const Header = styled.h2`
  font-size: 15px;
  font-weight: 500;
  margin-top: 2em;
`;

const List = styled.dl`
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  user-select: none;
`;

const Keys = styled.dt`
  float: right;
  width: 45%;
  margin: 0 0 10px;
  clear: left;
  text-align: right;
  font-size: 12px;
  color: ${s("textSecondary")};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Label = styled.dd`
  float: left;
  width: 55%;
  margin: 0 0 10px;
  display: flex;
  align-items: center;
  color: ${s("textSecondary")};
`;

export default React.memo(KeyboardShortcuts);
