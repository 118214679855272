import * as React from "react";
import styled from "styled-components";
import Empty from "~/components/Empty";
import Fade from "~/components/Fade";
import Flex from "~/components/Flex";

export default function FullscreenLoading() {
  return (
    <fade timing="{500}">
      <centered>
        <empty>Loading...</empty>
      </centered>
    </fade>
  );
}

const Centered = styled(Flex)`
  text-align: center;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
