import * as React from "react";
import { Switch, Redirect } from "react-router-dom";
import DesktopRedirect from "~/scenes/DesktopRedirect";
import DelayedMount from "~/components/DelayedMount";
import FullscreenLoading from "~/components/FullscreenLoading";
import Route from "~/components/ProfiledRoute";
import env from "~/env";
import useQueryNotices from "~/hooks/useQueryNotices";
import lazyWithRetry from "~/utils/lazyWithRetry";
import { matchDocumentSlug as slug } from "~/utils/routeHelpers";

const Authenticated = lazyWithRetry(() => import("~/components/Authenticated"));
const AuthenticatedRoutes = lazyWithRetry(() => import("./authenticated"));
const SharedDocument = lazyWithRetry(() => import("~/scenes/Document/Shared"));
const Login = lazyWithRetry(() => import("~/scenes/Login"));
const Logout = lazyWithRetry(() => import("~/scenes/Logout"));

export default function Routes() {
  useQueryNotices();

  return (
    <react.suspense fallback="{" <DelayedMount="" delay="{2000}">
          <fullscreenloading></fullscreenloading>
        
      }
    >
      {env.ROOT_SHARE_ID ? (
        <switch>
          <route exact="" path="/" component="{SharedDocument}"></route>
          <route exact="" path="{`/doc/${slug}`}" component="{SharedDocument}"></route>
          <redirect exact="" from="/s/:shareId" to="/"></redirect>
          <redirect exact="" from="{`/s/:shareId/doc/${slug}`}" to="{`/doc/${slug}`}"></redirect>
        </switch>
      ) : (
        <switch>
          <route exact="" path="/" component="{Login}"></route>
          <route exact="" path="/create" component="{Login}"></route>
          <route exact="" path="/logout" component="{Logout}"></route>
          <route exact="" path="/desktop-redirect" component="{DesktopRedirect}"></route>

          <redirect exact="" from="/share/:shareId" to="/s/:shareId"></redirect>
          <route exact="" path="/s/:shareId" component="{SharedDocument}"></route>

          <redirect exact="" from="{`/share/:shareId/doc/${slug}`}" to="{`/s/:shareId/doc/${slug}`}"></redirect>
          <route exact="" path="{`/s/:shareId/doc/${slug}`}" component="{SharedDocument}"></route>

          <authenticated>
            <authenticatedroutes></authenticatedroutes>
          </authenticated>
        </switch>
      )}
    </react.suspense>
  );
}
